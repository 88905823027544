import { Button } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React, { useCallback, useEffect, useState } from 'react';
import { cancelReceipts } from '../services/api';

const ac = new AbortController();

function CancelReceipts(_: RouteComponentProps) {
  const [listOfIds, setListOfIds] = useState<any[]>([]);
  const [listOfFiskaldIds, setListOfFiskaldIds] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);
  const [speed, setSpeed] = useState(20);

  const isStorno = useCallback(
    (value: any) => listOfFiskaldIds.some((v: any) => v.ID === value.ID),
    [listOfFiskaldIds]
  );

  const afterRestart = (value: any) => {
    console.log('Stornirano', value);
    setListOfFiskaldIds((old) => [...old, { ID: value.ID }]);
    setLoading(false);
    setStarted(false);
  };

  const cancelAddedReceipts = useCallback(() => {
    if (loading) {
      console.log('Already loading!');
      return;
    }
    console.log('INITIATED');

    const currentIds = listOfIds
      .filter((value) => !isStorno(value))
      .slice(0, speed);

    if (currentIds.length === 0) {
      setStarted(false);
      return;
    }

    setLoading(true);
    const startTime = Date.now();
    cancelReceipts(currentIds, ac.signal).then((value) => {
      // console.log('Stornirano', currentIds);
      setListOfFiskaldIds((old) => [...old, ...currentIds]);
      setLoading(false);
      setTimeSpent((Date.now() - startTime) / 1000);
      // console.log('List of fiskald', listOfFiskaldIds);
    });
  }, [listOfIds, speed, loading, isStorno]);

  useEffect(() => {
    console.log('STARTED: ', started);
    console.log('LOADING: ', loading);
    if (started && !loading) {
      console.log('List of fiskald after called', listOfFiskaldIds);
      cancelAddedReceipts();
    }
  }, [listOfFiskaldIds, cancelAddedReceipts, started, loading]);

  return (
    <React.Fragment>
      <div>
        <label>
          Unesi JSON listu objekata sa vrednosti "ID": nesto ili listu ID-eva
          (1, 2, 3, ...)
        </label>
        <textarea
          style={{ width: '100%', height: '100px' }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.currentTarget.value;
            if (value.startsWith('[') || value.startsWith('{')) {
              try {
                const list = JSON.parse(value);
                setListOfIds(value.startsWith('{') ? [list] : list);
              } catch (e) {}
              return;
            }
            try {
              const splittedMaybe = value.split(',');
              setListOfIds(
                splittedMaybe
                  .map((value) => parseInt(value))
                  .filter((value) => !isNaN(value))
                  .map((value) => ({ ID: value }))
              );
            } catch (e) {}
          }}
        ></textarea>
      </div>
      <div>
        <label>Unesi brzinu storniranja:</label>
        <input
          defaultValue={speed}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            setSpeed(parseInt(value));
          }}
        />
      </div>
      <Button
        variant="outlined"
        color="secondary"
        disabled={loading}
        onClick={() => setStarted(true)}
      >
        ZAPOČNI
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        disabled={!loading}
        onClick={() => {
          setStarted(false);
          setLoading(false);
          ac.abort();
        }}
      >
        ODUSTANI
      </Button>
      <p>
        STORNIRANO: {listOfFiskaldIds.length}/{listOfIds.length}
      </p>
      {timeSpent > 0 && (
        <p>Zadnje trajanje storniranja u sekundama: {timeSpent}</p>
      )}
      {timeSpent > 0 && (
        <p>
          Procenjeno preostalo vreme u minutama:{' '}
          {timeSpent *
            (listOfIds.filter((value) => !isStorno(value)).length / speed)}
        </p>
      )}
      {timeSpent > 0 && (
        <p>
          Očekivani završetak:{' '}
          {new Date(
            Date.now() +
              timeSpent *
                1000 *
                (listOfIds.filter((value) => !isStorno(value)).length / speed)
          ).toLocaleString()}
        </p>
      )}
      <div style={{ marginBottom: '10px' }}>
        {listOfFiskaldIds
          .slice(listOfFiskaldIds.length - speed * 2)
          .map((value: any, index) => (
            <Button
              key={index}
              variant={'contained'}
              color={'primary'}
              disabled={true}
            >
              {value.ID} {'STORNIRAN'}
            </Button>
          ))}
      </div>
      {listOfIds
        .filter((value) => !isStorno(value))
        .slice(0, speed * 10)
        .map((value: any, index) => (
          <Button
            key={index}
            variant={'outlined'}
            color={'secondary'}
            onClick={() => {
              setLoading(true);
              cancelReceipts([{ ID: value.ID }], ac.signal).then(() =>
                afterRestart(value)
              );
            }}
            disabled={loading}
          >
            {value.ID}
          </Button>
        ))}
    </React.Fragment>
  );
}
export default CancelReceipts;
