// Hook
const SERVER_IP = 'https://cis.tika-zlatnik.hr:3001';

export async function getRequest(
  endpoint: string,
  body?: any,
  method?: 'GET' | 'POST',
  signal?: AbortSignal
) {
  const fetchOptions: any = {
    method: method || (body ? 'POST' : 'GET'),
    headers: { 'Content-Type': 'application/json' },
    signal: null,
  };

  let url = `${SERVER_IP}/${endpoint}`;

  if (method === 'GET' && body) {
    url +=
      '?' +
      Object.keys(body)
        .filter((key) => body[key] !== undefined)
        .map((key) =>
          Array.isArray(body[key])
            ? body[key]
                .map((value: any, index: number) => `${key}[${index}]=${value}`)
                .join('&')
            : `${key}=${body[key]}`
        )
        .join('&');
  } else if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  if (signal) {
    fetchOptions.signal = signal;
  }

  let anyResponse: Response;
  try {
    anyResponse = await fetch(url, fetchOptions);
  } catch (error) {
    console.error(error);
    return {};
  }

  try {
    const response = await anyResponse.json();
    return response;
  } catch (_) {}

  if (anyResponse.ok) {
    return true;
  }
}

export async function getServerCompanyData(body?: any) {
  return getRequest('company', body, 'GET');
}

export async function getServerFiskalData(body?: any) {
  return getRequest('receipt', body, 'GET');
}

export async function getServerStatusData() {
  return getRequest('status', {}, 'GET');
}

export async function cancelReceipts(body: any, signal: AbortSignal) {
  return getRequest('cancel', body, 'POST', signal);
}
